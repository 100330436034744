import React from 'react';
import { Route, Switch } from "react-router-dom"
import Login from "./components/Login/Login"
import Dashboard from './components/Dashboard/Dashboard';
import LanguageList from './languages/List'
import QRScanner from "./components/Scanner/QRScanner"
import Tutorial from "./components/Tutorial/Tutorial"
import DataProcessorRouter from './components/DataProcessor/Router'
import PermissionProvider from './components/Permissions/Provider';
import TermsAndConditions from './components/Settings/Terms&Conditions';
import NotificationProvider from './components/Notifications/Provider';
import Notification from './components/Notifications/CustomNotification';

import "./css/Dashboard.css"
import "./css/DataProcessor.css"
import "./css/Login.css"
import "./css/Tutorial.css"
import RedirectRoute from './components/RedirectRoute';
import MessageDecoder from "./components/Scanner/MessageDecoder";

function App() {

 

  return (
    <div className="App">
      <NotificationProvider>
        <PermissionProvider>
          <Switch>
            <RedirectRoute exact path="/"/>
            <Route path="/authenticate" component={Login} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/languages" component={LanguageList} />
            <Route path="/scanner" component={QRScanner} />
            <Route path="/tutorial" component={Tutorial} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/data-processor/:id" component={DataProcessorRouter} />
            <Route path="/decode" component={MessageDecoder} />
          </Switch>
          <Notification />
        </PermissionProvider>
      </NotificationProvider>
    </div>
  );
}

export default App;
