import {
    Button, CircularProgress, createStyles, Divider, IconButton, List, ListItem,
    ListItemText, makeStyles, MobileStepper, Theme, Typography
} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import PermissionContext, {Process} from '../Permissions/Context';
import BottomNav from "../CustomBottomNav";
import TermsCard from '../Settings/T&C';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditForm from '../EditForm';
import { IField } from './Data';
import HelpIcon from '@material-ui/icons/Help';
import ConfirmDialog from '../Notifications/ConfirmDialog';
import FileUploadForm from "../FileUploadForm";
import { Dropdown } from 'primereact/dropdown';

interface IRequest {
    permissionMessage?: string
    enforcedFileName?: string
    redirectUri?: string
    target?: string
    sourceDataProcessors?: Array<{}>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '85%',
            maxWidth: '36ch',
            backgroundColor: theme.palette.background.paper,
            margin: "20px auto",
            height: "auto",
        },
        inline: {
            display: 'inline',
        },
        button: {
            textTransform: 'none',
            fontWeight: 'bold'
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            margin: 0
        },
        dot: {
            backgroundColor: "grey"
        },
        dotActive: {
            backgroundColor: "white"
        }
    }),
);

const DataProcessorRequest = (request: IRequest) => {
    const history = useHistory()
    // const { id } = useParams<{ id: string }>();
    const {
        resource, rqResource, resources,
        grantPermission, grantPermissionForBulkData, grantPermissionForDataFromDP,
        editBulkDataForPermission, denyPermission
    } = useContext(PermissionContext)
    const [hash, setHash] = useState()
    const [activeStep, setStep] = useState(0);
    const [edit, setEdit] = useState(false);
    const [fieldInfo, setFieldInfo] = useState<[IField]>()
    const [editData, setEditData] = useState()
    const [files, setFiles] = useState<File[]>()
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedDataProcessor, setSelectedDataProcessor] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        const data1: any = rqResource;
        const data2: [IField] = data1?.content.fieldset;
        let obj: any = {};
        data2.forEach((entry: IField) => obj[entry.uuid] = entry.value)
        setFieldInfo(data2);
        setEditData(obj)
    }, [rqResource]);

    // show all fields in the request
    const dataEntry = (entries: any) => {
        return (
            <List className={classes.root}>
                {Object.values(entries).map((element: any, index) =>
                    <>
                        {
                            <>
                                <ListItem key={element.uuid} alignItems="flex-start" className={classes.item}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    style={{ lineHeight: 0, fontSize: "15px" }}
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textSecondary"
                                                >
                                                    {element.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    style={{ fontSize: "17px", lineHeight: 0 }}
                                                    component="span"
                                                    variant="body1"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {element.value ? element.value : "No data"}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                {index < Object.values(entries).length - 1 && <Divider component="li" />}
                            </>
                        }

                    </>
                )}
            </List>
        )
    }

    let title: string = " ";
    let URI: string = " ";
    let policyLink = "";
    let process: Process
    let data = "";
    let permissionForCurrentRequest: { hash: "" } | undefined;

    if (rqResource) {
        const permissionContent: any = rqResource.content;
        title = permissionContent.data_processor.commonName
        URI = permissionContent.data_processor.URI
        policyLink = permissionContent.legal_policy;
        process = Process[permissionContent.process as keyof typeof Process]
        data = permissionContent.data
    } else {
        return <CircularProgress style={{ marginTop: "20px" }} />
    }

    if (resource) {
        permissionForCurrentRequest = resource.find((permission: { data: string; }) => permission.data == data)
    } else {
        return <CircularProgress style={{ marginTop: "20px" }} />
    }

    const increaseStep = () => {
        if (activeStep === 1) {
            setDialogOpen(true);
        } else {
            setStep(activeStep + 1);
        }
    }

    const decreaseStep = () => {
        setStep(activeStep - 1);
    }

    const submitStep = () => {
        if (activeStep === 1) {
            return "Allow Access"
        }
        return "Continue"
    }

    const open = () => {
        setEdit(true)
    }

    const onSubmit = (fieldInfo: any, editedData: any) => {
        const parsedFieldInfoData: [IField] = fieldInfo;
        setFieldInfo(parsedFieldInfoData)
        setEditData(editedData)
        setEdit(false)
    }

    const onFileSelect = (files: File[]) => {
        setFiles(files)
    }

    const denied = () => {
        denyPermission(request.permissionMessage)
        history.push("/dashboard")
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    const redirectBackToOrigin = () => {
        if (request.redirectUri) {
            if (request.target) {
                window.open(request.redirectUri, '_blank');
            } else {
                window.location.replace(request.redirectUri)
            }
        }
    }

    const confirm = () => {
        if (process === Process.INDIVIDUAL) {
            if (selectedDataProcessor) {
                grantPermissionForDataFromDP(request.permissionMessage, selectedDataProcessor)
            } else {
                grantPermission(request.permissionMessage, editData)
            }
        } else if (process === Process.BULK) {
            // if permission already exists, edit (send new bulk data), else grant permission
            if (permissionForCurrentRequest) {
                editBulkDataForPermission(permissionForCurrentRequest.hash, files)
            } else {
                // request.permissionMessage is the b64 encoded permission request message
                grantPermissionForBulkData(request.permissionMessage, files)
            }
        }
        history.push("/dashboard")
        setTimeout(redirectBackToOrigin, 3000);
    }

    const editView = () => {
        return (
            <>
                <div className="FlatHeader" style={{ justifyContent: "space-between" }}>
                    <Button onClick={() => setEdit(false)} style={{ color: "white" }} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                    <div className="tl" style={{ color: "white", fontWeight: "bold" }}>
                        Edit
                    </div>
                    <Button form="my-form" type="submit" style={{ color: "white" }}>
                        Save
                    </Button>
                </div>
                <div className="Content">
                    <EditForm data={fieldInfo} submitHandler={onSubmit} name={title} img={URI} />
                </div>
            </>
        )
    }

    const dataView = () => {
        return (
            <>
                <div className="Search" style={{ justifyContent: "space-between" }}>
                    <div style={{ height: "100%" }}>
                        <Button onClick={() => history.push("/dashboard")} style={{ color: "white", marginTop: "10px", marginLeft: "5px" }} className={classes.button}>
                            Close
                        </Button>
                    </div>
                    <div className="tl" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img src={URI} width="25" height="25" style={{ background: "white", margin: "5" }} />
                        <text style={{ color: "white", margin: 0 }}>{title}</text>
                        <text style={{ color: "white", margin: 0, fontSize: 12 }}>Requests your permission for personal data</text>
                        <MobileStepper
                            style={{ background: "inherit" }}
                            variant="dots" position="static"
                            steps={2}
                            activeStep={activeStep}
                            nextButton={null}
                            backButton={null}
                            classes={{ dot: classes.dot, dotActive: classes.dotActive }}
                        />
                    </div>
                    <div style={{ width: "64px", height: "36px" }}></div>
                </div>
                <div className="ListContent">
                    {activeStep === 0 && fieldInfo ?
                        <>
                            { request.sourceDataProcessors?.length ?
                                <>
                                    <div style={{ marginTop: "15px", width: "80%", alignSelf: "center", fontSize: 14, fontWeight: 400 }}>
                                        <text>Select a data processor to share sensitive data securely from it.</text>
                                    </div>
                                    <div style={{ marginTop: "15px", width: "80%", alignSelf: "center", fontSize: 14, fontWeight: 400 }}>
                                        <Dropdown value={selectedDataProcessor}
                                              onChange={(e) => setSelectedDataProcessor(e.value)}
                                              options={request.sourceDataProcessors} optionLabel="name"
                                              placeholder="Select a Data Processor"
                                              className="w-full md:w-14rem"
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ marginTop: "15px", width: "80%", alignSelf: "center", fontSize: 14, fontWeight: 400 }}>
                                        <text>Share sensitive data securely from a dataU organization</text> <HelpIcon style={{ color: "#004C60", width: 15, height: 15 }} />
                                    </div>
                                    {process === Process.INDIVIDUAL && dataEntry(fieldInfo)}
                                    {process === Process.BULK && <FileUploadForm enforcedFileName={request.enforcedFileName} fileSelectHandler={onFileSelect}/>}
                                </>
                            }
                        </>
                        :
                        <>
                            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                <IconButton style={{cursor: 'crosshair'}} onClick={decreaseStep}><ArrowBackIcon /></IconButton>
                                <Typography style={{ color: "textPrimary", fontSize: 14, fontWeight: "bold" }}>
                                    Review terms and conditions
                                </Typography>
                                <div style={{ width: "48px", height: "48px" }}></div>
                            </div>
                            <TermsCard url={policyLink} />
                        </>
                    }
                </div>
                {
                    process === Process.INDIVIDUAL ?
                        <BottomNav goNext={increaseStep} goBack={decreaseStep} finishString={submitStep} edit={open} cancel={denied} />
                        :
                        <BottomNav goNext={increaseStep} goBack={decreaseStep} finishString={submitStep} cancel={denied} />
                }
            </>
        )
    }

    return (
        <div className="ProcessorContainer">
            <ConfirmDialog open={dialogOpen} closeDialog={closeDialog} handleConfirm={confirm} dialogType="accept" />

            {edit ? editView() : dataView() }

        </div >
    )
}
export default DataProcessorRequest;
