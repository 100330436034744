import React, {useReducer} from "react"
import {useHistory} from "react-router-dom";
import useNotification from "../Notifications/NotificationHook";
import PermissionContext, {ActionType, initialState} from "./Context"

import permissionReducer from "./Reducer"

const PermissionProvider = (props: React.PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(permissionReducer, initialState);

    const { addNotification } = useNotification()        

    const history = useHistory();

    async function getData(url = '') {
        return await fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }).then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                if (response.status === 401) {
                    addNotification('You are unauthorized, please login', response.status)
                    history.push('/authenticate')
                } else {
                    const responseBody = await response.json();
                    addNotification(responseBody.error, response.status)
                    history.push('/dashboard')
                }
            }
        })
    }

    async function postData(url = '', data = {}) {
        // Default options are marked with *
        return await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                 if (response.status === 401) {
                     addNotification('You are unauthorized, please login', response.status)
                     history.push('/authenticate')
                } else {
                     const responseBody = await response.json();
                     addNotification(responseBody.error, response.status)
                     history.push('/dashboard')
                }
            }
        })
    }

    const getDataProcessors = async () => {
        const url = '/api/data-processors';
        dispatch({ kind: ActionType.SET_LOADING, loading: true })
        getData(url)
            .then(response => {
                dispatch({ kind: ActionType.SET_RESOURCES, resources: response });
                dispatch({ kind: ActionType.SET_LOADING, loading: false });
                dispatch({ kind: ActionType.SET_REQUEST_RESOURCE, rqResource: undefined })
                
            })
            .catch(error => {console.log(error)
                dispatch({ kind: ActionType.SET_RESOURCES, resources: undefined });
            })
            .finally(() => dispatch({ kind: ActionType.SET_LOADING, loading: false }))
    }

    const getDataProcessorsWithData = async (data: string) => {
        const url = '/api/data-processors?permission_data=' + encodeURI(data);
        getData(url)
            .then(response => {
                dispatch({ kind: ActionType.SET_FILTERED_DPS, dataFilteredProcessors: response });
                dispatch({ kind: ActionType.SET_LOADING, loading: false });
            })
            .catch(error => {
                console.log(error)
                dispatch({ kind: ActionType.SET_FILTERED_DPS, dataFilteredProcessors: undefined });
            })
            .finally(() => dispatch({ kind: ActionType.SET_LOADING, loading: false }))
    }

    const permissionsForDP = async (dataProcessor: string) => {
        const url = '/api/data-processors/' + dataProcessor;
        getData(url)
            .then(response => {
                const data = response;
                dispatch({ kind: ActionType.SET_RESOURCE, resource: data })
            })
    }

    const grantPermission = async (hash: string, data: any) => {
        const url = '/api/permissions/grant';
        const post_data = { 'message': hash, 'data': data }
        postData(url, post_data)
            .then(response => {
                const data = response;
                getDataProcessors()
            });
    }

    // granting permission for data held by another DP
    const grantPermissionForDataFromDP = async (hash: string, senderDataProcessor: string) => {
        const url = '/api/permissions/grant/from';
        const post_data = { 'message': hash, 'sender_dp': senderDataProcessor }

        postData(url, post_data).then(_ => { getDataProcessors() });
    }

     const grantPermissionForBulkData = async (encodedPermission: string, files: [File]) => {
        const url = '/api/permissions/grant/bulk';
        let formData = new FormData();
        formData.append('message', encodedPermission);
        files.forEach(file => {
           formData.append('data', file, file.name);
        });

        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {},
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: formData
        }).then(async response => {
            if (!response.ok) {
                if (response.status === 401) {
                    addNotification('You are unauthorized, please login', response.status)
                    history.push('/authenticate')
                } else {
                    const responseBody = await response.json();
                    addNotification(responseBody.error, response.status)
                    history.push('/dashboard')
                }
            } else {
                return response.json()
            }
        });
    }

    const editBulkDataForPermission = async (permissionHash: string, files: [File]) => {
        const url = '/api/permissions/edit/bulk';
        let formData = new FormData();
        formData.append('hash', permissionHash);
        files.forEach(file => {
           formData.append('data', file, file.name);
        });

        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {},
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: formData
        }).then(async response => {
            if (!response.ok) {
                if (response.status === 401) {
                    addNotification('You are unauthorized, please login', response.status)
                    history.push('/authenticate')
                } else {
                    const responseBody = await response.json();
                    addNotification(responseBody.error, response.status)
                    history.push('/dashboard')
                }
            } else {
                return response.json()
            }
        });
    }

    function revokePermission(hash: any) {
        const url = '/api/permissions/revoke';
        const post_data = { 'hash': hash }
        postData(url, post_data)
            .then(response => {
                getDataProcessors()
            });
    }

    function denyPermission(hash: any) {
        const url = '/api/permissions/deny';
        const post_data = { 'message': hash }
        postData(url, post_data)
            .then(response => {
            });
    }

    function decodePermission(hash: any) {
        const url = '/api/permissions/decode';
        const post_data = { 'message': hash }
        postData(url, post_data)
            .then(response => {
                const data = response;
                dispatch({ kind: ActionType.SET_REQUEST_RESOURCE, rqResource: data})
            });
    }

    const editPermission = async (hash: string, data: any) => {
        const url = '/api/permissions/edit';
        const post_data = { 'hash': hash, 'data': data };
        postData(url, post_data)
            .then(response => {
                const data = response;
                dispatch({kind: ActionType.SET_RESOURCE, resource: undefined})
            })
    }

    return (
        <PermissionContext.Provider
            value={{
                ...state,
                getDataProcessors,
                getDataProcessorsWithData,
                permissionsForDP,
                grantPermission,
                grantPermissionForDataFromDP,
                grantPermissionForBulkData,
                revokePermission,
                decodePermission,
                editPermission,
                editBulkDataForPermission,
                denyPermission,
            }}
        >
            {props.children}
        </PermissionContext.Provider>
    );
};
export default PermissionProvider;