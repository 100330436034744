import {
    Button, CircularProgress, createStyles, Divider, IconButton, List, ListItem
    , ListItemText, makeStyles, MobileStepper, Theme, Typography
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PermissionContext, {Process} from '../Permissions/Context';
import DataActionsBar from "../DataActionsBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditForm from '../EditForm';
import TermsCard from '../Settings/T&C';
import ConfirmDialog from '../Notifications/ConfirmDialog';
import FileUploadForm from "../FileUploadForm";
import BottomNav from "../CustomBottomNav";

export interface IField {
    value: string;
    mime: string,
    name: string,
    uuid: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '85%',
            maxWidth: '36ch',
            backgroundColor: theme.palette.background.paper,
            margin: "20px auto",
            height: "auto",
        },
        inline: {
            display: 'inline',
        },
        button: {
            textTransform: 'none',
            fontWeight: 'bold'
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0
        },
        dot: {
            backgroundColor: "grey"
        },
        dotActive: {
            backgroundColor: "white"
        }
    }),
);

const DataProcessorInformation = () => {
    const history = useHistory()
    const { id } = useParams<{ id: string }>();
    const { resource, permissionsForDP, revokePermission, editPermission } = useContext(PermissionContext)
    const classes = useStyles();
    const [edit, setEdit] = useState(false);
    const [process, setProcess] = useState<Process>();
    const [termsLink, setTermsLink] = useState<String>();
    const [permissionIndex, setPermissionIndex] = useState(0);
    const [fieldInfo, setFieldInfo] = useState<[IField]>()
    const [activeStep, setStep] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    // show list of all data known to DP
    const dataEntry = (entries: any) => {
        return entries.map((entry: any, index: number) =>
            <>
                <List className={classes.root}>
                    {entry.process === "BULK" &&
                        <ListItem alignItems="flex-start" className={classes.item}>
                            <ListItemText style={{ marginTop: "7px", marginBottom: "7px" }}
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            style={{ lineHeight: 0, fontSize: "15px" }}
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textSecondary"
                                        >
                                            {entry.data}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    }
                    {entry.process === "BULK" && Object.values(entry.fieldset).map((element: any, index) =>
                            <>
                                {
                                    <ListItem key={element.uuid} alignItems="flex-start" className={classes.item}>
                                        <ListItemText style={{ marginTop: "7px", marginBottom: "7px" }}
                                            primary={
                                                <React.Fragment>
                                                    <Typography
                                                        style={{ lineHeight: 0, fontSize: "15px" }}
                                                        component="span"
                                                        variant="body2"
                                                        className={classes.inline}
                                                        color="textSecondary"
                                                    >
                                                        {element.name}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                }
                                {index < Object.values(entry.fieldset).length - 1 && <Divider />}
                            </>
                        )
                    }
                    { entry.process === "INDIVIDUAL" && Object.values(entry.fieldset).map((element: any, index) =>
                        <>
                            {
                                <ListItem key={element.uuid} alignItems="flex-start" className={classes.item}>
                                    <ListItemText style={{ marginTop: "7px", marginBottom: "7px" }}
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    style={{ lineHeight: 0, fontSize: "15px" }}
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textSecondary"
                                                >
                                                    {element.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    style={{ fontSize: "17px", lineHeight: 0 }}
                                                    component="span"
                                                    variant="body1"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {element.value ? element.value : "No data"}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            }
                            {index < Object.values(entry.fieldset).length - 1 && <Divider />}
                        </>
                    )}
                </List>
                <DataActionsBar
                    edit={open}
                    index={index}
                    goNext={increaseStep}
                    goBack={decreaseStep}
                    finishString={submitStep}
                    cancel={openDialog}
                />
            </>
        )
    }

    useEffect(() => {
        permissionsForDP(id);
    }, []);

    let title: string = " ";
    let URI: string = " ";
    let permissions: any;

    if (resource && resource.length > 0) {
        permissions = resource;

        // take DP title and image URI from the first permission
        title = permissions[0].data_processor.commonName
        URI = permissions[0].data_processor.URI
    } else {
        return <CircularProgress style={{ marginTop: "40vh" }} />
    }

    const open = (index: number) => {
          if (permissions) {
              const dataFields: [IField] = permissions[index]?.fieldset;
              setProcess(Process[permissions[index].process as keyof typeof Process])
              setTermsLink(permissions[index].legal_policy)
              setPermissionIndex(index)
              setFieldInfo(dataFields);
              setEdit(true)
        }  
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    const openDialog = (index: number) => {
        setDialogOpen(true);
        setPermissionIndex(index)
    }

    const onSubmit = (fieldInfo: any, editedData: any) => {
        const permissionHash: any = permissions[permissionIndex].hash;
        editPermission(permissionHash, editedData);
        history.push("/dashboard")
    }

    const revoke = () => {
        revokePermission(permissions[permissionIndex].hash)
        history.push('/dashboard')
    }

    const increaseStep = () => {
        if (activeStep === 1) {
            history.push("/dashboard")
        }
        setStep(activeStep + 1);
    }

    const decreaseStep = () => {
        setStep(activeStep - 1);
    }

    const submitStep = () => {
        if (activeStep === 0) {
            return "Continue"
        }
        if (activeStep === 1) {
            return "Finish"
        }
        return "Continue"
    }

    return (
        <div className="ProcessorContainer">
            <ConfirmDialog open={dialogOpen} closeDialog={closeDialog} handleConfirm={revoke} dialogType="remove"/>
            { edit ?
                <>
                    <div className="FlatHeader" style={{ justifyContent: "space-between" }}>
                        <Button onClick={() => setEdit(false)} style={{ color: "white" }} startIcon={<ArrowBackIcon />}>
                            Back
                        </Button>
                        <div className="tl" style={{ color: "white", fontWeight: "bold" }}>
                            Edit
                        </div>
                        <Button form="my-form" type="submit" style={{ color: "white" }}>
                            Save
                        </Button>
                    </div>
                    <div className="Content">
                        {process === Process.BULK ?
                            <FileUploadForm fileSelectHandler={onSubmit}/>
                            :
                            <EditForm data={fieldInfo} submitHandler={onSubmit} img={URI} name={title} />
                        }
                    </div>
                </>
                :
                <>
                    <div className="Search" style={{ justifyContent: "space-between" }}>
                        <div style={{ height: "100%" }}>
                            <Button onClick={history.goBack} style={{ color: "white", marginTop: "10px", marginLeft: "5px" }} className={classes.button}>
                                Close
                        </Button>
                        </div>
                        <div className="tl" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={URI} width="25" height="25" style={{ background: "white", margin: "5" }} />
                            <text style={{ color: "white", margin: 0 }}>{title}</text>
                            <text style={{ color: "white", margin: 0, fontSize: 12 }}>Review your personal data</text>
                            <MobileStepper
                                style={{ background: "inherit" }}
                                variant="dots" position="static"
                                steps={2}
                                activeStep={activeStep}
                                nextButton={null}
                                backButton={null}
                                classes={{ dot: classes.dot, dotActive: classes.dotActive }}
                            />
                        </div>
                        <div style={{ width: "64px", height: "36px" }}></div>
                    </div>
                    <div className="ListContent">
                        {activeStep === 0 ? dataEntry(resource) :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                    <IconButton onClick={decreaseStep}><ArrowBackIcon /></IconButton>
                                    <Typography style={{ color: "textPrimary", fontSize: 14, fontWeight: "bold" }}>
                                        Review terms and conditions
                                    </Typography>
                                    <div style={{ width: "48px", height: "48px" }}></div>
                                </div>
                                <TermsCard url={termsLink} />
                            </>
                        }
                    </div>
                </>
            }
        </div>
    )
}
export default DataProcessorInformation;